import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const NumberViewModule = new Module({
  id: 'number',
  name: 'Number',
  label: 'Number',
  path: 'number',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/Number/View'))
  )
});

export const NumberAddModule = new Module({
  id: 'number-add',
  name: 'NumberAdd',
  path: 'number/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/Number/Form'))
  ),
  actions: {
    add: {
      id: 'add-Number',
      name: 'add-Number',
      label: 'Add Number'
    }
  }
});

const NumberModules = [NumberViewModule, NumberAddModule];

export default new ModuleBundle('Number Group', NumberModules);
