import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import GroupModule from 'src/content/WhiteListing/Groups/module';
import NumberModule from 'src/content/WhiteListing/Number/module';

// Management

const whiteListingRoutes = [
  registerModulesWithRouter(GroupModule),
  registerModulesWithRouter(NumberModule)
];

export default whiteListingRoutes;
