import axiosInt from './axios';

const { default: axios } = require('axios');

axios.interceptors.request.use(
  async (config) => {
    // const session = await Auth.currentSession();
    // const token = await session.getIdToken().getJwtToken();
    const token = axiosInt.defaults.headers.common.Authorization;
    if (token) config.headers.Authorization = token;

    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // if (error.response.status === 403) Auth.signOut();

    return Promise.reject(error);
  }
);

export function doPost(url, payload, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .post(url, payload, {
        params,
        headers
      })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doGet(url, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();

    axios
      .get(url, {
        params,
        headers
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((err) => reject(err?.response?.data));
  });
}

export function doGetById(url, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .get(url, { params, headers })
      .then(({ data }) => resolve({ ...data }))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doPut(url, payload, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .put(url, payload, { params, headers })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doDelete(url, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .delete(url, { params, headers })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data));
  });
}

export function doPatch(url, payload, params, headers, cancelToken) {
  return new Promise(async (resolve, reject) => {
    if (cancelToken) await cancelToken.cancel();
    axios
      .patch(url, payload, { params, headers })
      .then(({ data }) => resolve(data))
      .catch((err) => reject(err?.response?.data?.message));
  });
}

const ApiCallers = {
  doPost,
  doGet,
  doGetById,
  doPut,
  doPatch,
  doDelete
};

export default ApiCallers;
