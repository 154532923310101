import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const data = [
  {
    vehicleName: 'Maruti Swift - MH01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    driverName: 'Rahul Sharma',
    driverContact: '+91 9876543210',
    driverId: 'EMP001',
    type: 'Main Battery Disconnected',
    timestamp: '2023-07-25T10:15:00Z'
  },
  {
    vehicleName: 'Hyundai Creta - GJ05CD5678',
    vehicleId: '615e8b3c8e39052a9e6c3542',
    driverName: 'Amit Patel',
    driverContact: '+91 8765432109',
    driverId: 'EMP002',
    type: 'GPS Device Removed',
    timestamp: '2023-07-25T11:30:00Z'
  },
  {
    vehicleName: 'Toyota Innova - DL07EF9012',
    vehicleId: '615e8b3c8e39052a9e6c3543',
    driverName: 'Sandeep Singh',
    driverContact: '+91 7654321098',
    driverId: 'EMP003',
    type: 'GPS Unit Opened',
    timestamp: '2023-07-25T15:45:00Z'
  },
  {
    vehicleName: 'Honda City - MH04IJ7890',
    vehicleId: '615e8b3c8e39052a9e6c3544',
    driverName: 'Rajesh Gupta',
    driverContact: '+91 6543210987',
    driverId: 'EMP004',
    type: 'Main Battery Disconnected',
    timestamp: '2023-07-25T16:00:00Z'
  },
  {
    vehicleName: 'Ford EcoSport - RJ14MN4567',
    vehicleId: '615e8b3c8e39052a9e6c3545',
    driverName: 'Ankur Verma',
    driverContact: '+91 5432109876',
    driverId: 'EMP005',
    type: 'Main Battery Disconnected',
    timestamp: '2023-07-25T17:20:00Z'
  },
  {
    vehicleName: 'Mahindra XUV500 - UP32KL2345',
    vehicleId: '615e8b3c8e39052a9e6c3546',
    driverName: 'Arun Kumar',
    driverContact: '+91 4321098765',
    driverId: 'EMP006',
    type: 'GPS Device Removed',
    timestamp: '2023-07-25T18:30:00Z'
  },
  {
    vehicleName: 'Volkswagen Polo - HR26OP5678',
    vehicleId: '615e8b3c8e39052a9e6c3547',
    driverName: 'Nikhil Sharma',
    driverContact: '+91 3210987654',
    driverId: 'EMP007',
    type: 'GPS Device Removed',
    timestamp: '2023-07-25T19:15:00Z'
  },
  {
    vehicleName: 'Tata Nexon - TN01QR7890',
    vehicleId: '615e8b3c8e39052a9e6c3548',
    driverName: 'Vikas Singh',
    driverContact: '+91 2109876543',
    driverId: 'EMP008',
    type: 'GPS Unit Opened',
    timestamp: '2023-07-25T20:00:00Z'
  },
  {
    vehicleName: 'Renault Kwid - DL09ST1234',
    vehicleId: '615e8b3c8e39052a9e6c3549',
    driverName: 'Gaurav Patel',
    driverContact: '+91 1098765432',
    driverId: 'EMP009',
    type: 'Main Battery Disconnected',
    timestamp: '2023-07-25T21:45:00Z'
  },
  {
    vehicleName: 'Chevrolet Beat - MH02QR5678',
    vehicleId: '615e8b3c8e39052a9e6c3550',
    driverName: 'Rakesh Gupta',
    driverContact: '+91 9876543210',
    driverId: 'EMP010',
    type: 'Main Battery Disconnected',
    timestamp: '2023-07-25T22:30:00Z'
  }
];

mock.onGet('/api/device-tampering').reply(() => {
  return [200, data];
});
