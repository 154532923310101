import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import registerModuleWithMenu from 'src/config/AccessControl/registerModuleWithMenu';
import { UserViewModule } from 'src/content/management/Users/module';
import { OrganisationViewModule } from 'src/content/management/Organisation/module';
import { RoleViewModule } from 'src/content/management/Role/module';
import { DashboardModule } from 'src/content/Dashboard/module';
import { DashboardModulev2 } from 'src/content/Dashboardv2/module';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { SimInventoryModule } from 'src/content/Inventory/Sim/module';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

import { ActivationViewModule } from 'src/content/requests/Activation/module';

import { NumbersViewModule } from 'src/content/WhiteListing/Numbers/module';
import { IPViewModule } from 'src/content/WhiteListing/IPs/module';
import { JobViewModule } from 'src/content/Job/module';
import { GroupViewModule } from 'src/content/WhiteListing/Groups/module';
import { PlanViewModule } from 'src/content/management/Plans/module';
import { AuditTrailViewModule } from 'src/content/Report/AuditTrail/module';
import { NumberViewModule } from 'src/content/WhiteListing/Number/module';
import { RequestModuleView } from 'src/content/requests/Request/module';
import { PublicApiLogModule } from 'src/content/Report/PublicApiLog/module';
import { SimBulkActions } from 'src/content/SimBulkActions/module';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { WorkCalendarView } from 'src/content/WorkCalendar/module';
import { WalletViewModule } from 'src/content/Wallet/module';
import { WalletOperationViewModule } from 'src/content/management/WalletTransaction/module';
import { SIMDiagnosisViewModule } from 'src/content/SIMDiagnosis/module';

const sortAlphaAsc = (a, b) => a.name.localeCompare(b.name);

const menuItems = [
  {
    heading: '',
    id: 'root',
    path: '',
    items: [
      registerModuleWithMenu(DashboardModule, { icon: DashboardOutlinedIcon }),
      registerModuleWithMenu(DashboardModulev2, {
        icon: DashboardOutlinedIcon
      }),
      registerModuleWithMenu(SimInventoryModule, {
        icon: Inventory2OutlinedIcon
      }),

      registerModuleWithMenu(SimBulkActions, {
        icon: ExposureOutlinedIcon
      }),
      // registerModuleWithMenu(TicketsViewModule, {
      //   icon: SupportAgentRoundedIcon
      // }),
      // registerModuleWithMenu(JobViewModule, {
      //   icon: WorkOutlineIcon
      // }),
      registerModuleWithMenu(WorkCalendarView, {
        icon: EventOutlinedIcon
      }),
      registerModuleWithMenu(RequestModuleView, {
        icon: AddTaskOutlinedIcon
      }),
      {
        name: 'Report',
        icon: AnalyticsOutlinedIcon,
        id: 'root',
        path: '/report',
        items: [
          registerModuleWithMenu(AuditTrailViewModule),
          registerModuleWithMenu(PublicApiLogModule)
        ].sort(sortAlphaAsc)
      },
      {
        name: 'Management',
        icon: SettingsTwoToneIcon,
        id: 'root',
        path: '/management',
        items: [
          registerModuleWithMenu(PlanViewModule),
          registerModuleWithMenu(UserViewModule),
          registerModuleWithMenu(RoleViewModule),
          registerModuleWithMenu(OrganisationViewModule),
          registerModuleWithMenu(WalletOperationViewModule)
        ].sort(sortAlphaAsc)
      },
      // {
      //   name: 'Requests',
      //   icon: IosShareOutlinedIcon,
      //   id: 'root',
      //   path: '/requests',
      //   items: [
      //     registerModuleWithMenu(ActivationViewModule),
      //     // registerModuleWithMenu(TerminationViewModule)
      //     registerModuleWithMenu(NumbersViewModule),
      //     registerModuleWithMenu(IPViewModule)
      //   ].sort(sortAlphaAsc)
      // },
      {
        name: 'White-Listing',
        icon: GradingOutlinedIcon,
        id: 'root',
        path: '/white-listing',
        items: [
          registerModuleWithMenu(GroupViewModule),
          registerModuleWithMenu(NumberViewModule)
        ].sort(sortAlphaAsc)
      }
    ]
  }
];

const filterSidebar = (items, filter) => {
  return (
    items
      // eslint-disable-next-line array-callback-return, consistent-return
      .map((item) => {
        if (item.items) item.items = filterSidebar(item.items, filter);
        if (filter(item.id)) return item;
      })
      .filter(Boolean)
  );
};

export { filterSidebar, menuItems };

// export default menuItems;
