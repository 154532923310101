import { SEARCH_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class SearchService extends ApiService {
  constructor() {
    super(SEARCH_URL);
  }
  getSearch = async (params, query) => {
    return this.doGet(`${SEARCH_URL}/${params}`, query);
  };
}
export default new SearchService();
