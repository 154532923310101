import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const SIMDetailsModule = new Module({
  id: 'sim-details',
  name: 'SIM Details',
  label: 'SIM Details',
  path: 'sim-details',
  Component: ComponentLoader(lazy(() => import('src/content/SIMDetails'))),
  actions: {
    // exportSIMDetailsPDF: {
    //   id: 'export-sim-pdf',
    //   name: 'exportSIMDetailsPDF',
    //   label: 'Export SIM Details'
    // }
    fetchDeviceDetails: {
      id: 'fetch-device-details',
      name: 'fetchDeviceDetails',
      label: 'Fetch Device Details'
    },
    simDiagnosis: {
      id: 'sim-diagnosis',
      name: 'simDiagnosis',
      label: 'Diagnose'
    }
  }
});

export default new ModuleBundle('SIMDetails', [SIMDetailsModule]);
