import { SidebarContext } from 'src/contexts/SidebarContext';

const { useEffect, useCallback, useContext } = require('react');

const useShortcut = (key, cb) => {
  const { closeSidebar } = useContext(SidebarContext);

  const createShortcuts = useCallback((e) => {
    if (e.ctrlKey && e.shiftKey && key === e.keyCode) {
      e.preventDefault();
      closeSidebar();
      cb();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', createShortcuts, true);
  }, []);
};

export default useShortcut;
