import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const trip = ['STOP', 'TRIP'];
const address = [
  {
    'Street Address': 'Garfield Avenue',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Country Club Road',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Rose Street',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Williams Street',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Franklin Avenue',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Windsor Drive',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Woodland Drive',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Cedar Avenue',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Arlington Avenue',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Country Club Road',
    City: 'Ajmer'
  },
  {
    'Street Address': 'River Street',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Devon Court',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Madison Avenue',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Fairway Drive',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Williams Street',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Clay Street',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Forest Street',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Willow Avenue',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Hanover Court',
    City: 'Ajmer'
  },
  {
    'Street Address': 'Essex Court',
    City: 'Ajmer'
  }
];

const getRandomNumber = (range) => {
  return Math.floor(Math.random() * range);
};
const type = 'STOP';

const vehicleTimeline = Array(20)
  .fill(0)
  .map((el, index) => {
    return {
      id: uuid(),
      startTime: `09:${getRandomNumber(60)} PM 8th May' 22`,
      endTime: `09:${getRandomNumber(60)} PM 8th May' 22`,
      date: "26th May' 22",
      vehicleRegNo: 'C5/W32-B/RJ01GB7891',
      ward: `Ward 0${getRandomNumber(15)}`,
      circle: `Circle 0${getRandomNumber(6)}`,
      duration: `${getRandomNumber(5)} hrs`,
      type: `${type}`,
      distance: 0,
      engineHours: `${type === 'STOP' ? 0 : getRandomNumber(59)}`,
      address: `${address[index]['Street Address']}, ${address[index].City}`
    };
  });

mock.onGet('/api/vehicle-timeline').reply(() => {
  return [200, { vehicleTimeline }];
});
