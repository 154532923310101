import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const TicketsViewModule = new Module({
  id: 'tickets',
  name: 'Tickets',
  label: 'Tickets',
  path: 'tickets',
  Component: ComponentLoader(lazy(() => import('src/content/Tickets/View')))
});

export const TicketAddModule = new Module({
  id: 'tickets-add',
  name: 'ticketsAdd',
  path: 'tickets/add',
  Component: ComponentLoader(lazy(() => import('src/content/Tickets/Form'))),
  actions: {
    add: {
      id: 'add-tickets',
      name: 'add-tickets',
      label: 'Add Tickets'
    }
  }
});

const TicketModules = [TicketsViewModule, TicketAddModule];

export default new ModuleBundle('Tickets', TicketModules);
