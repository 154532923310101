const esJSON = {
  // UI MENUS/ACTIONS
  'Language Switcher': 'भाषा स्विचर',
  'Sign out': 'साइन आउट',
  'View all notifications': 'सभी सूचनाएं देखें',
  Dashboard: 'डैशबोर्ड',
  'Sim Inventory': 'सिम इन्वेंटरी',
  Tickets: 'टिकट',
  Job: 'काम',
  Export: 'निर्यातs',
  Login: 'लॉग इन करें',
  'Forgot Password': 'पासवर्ड भूल गए',
  Maintenance: 'Mantenimiento',
  Welcome: 'स्वागत',
  Help: 'मदद',
  Search: 'खोज',
  Notifications: 'सूचनाएं',
  Settings: 'समायोजन',
  Management: 'प्रबंध',
  User: 'उपयोगकर्ता',
  Role: 'भूमिका',
  Plan: 'योजना',
  Organisation: 'संगठन',
  'White-Listing': 'श्वेत सूची',
  Activation: 'सक्रियण',
  Group: 'समूह',
  IP: 'आई पी',
  Numbers: 'नंबर',
  Requests: 'अनुरोध'
};

export default esJSON;
