import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 0, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      <img
        style={{ height: '10vh' }}
        src="/static/images/logo/ventel_full.png"
        alt="Ventel Logo"
      />
    </LogoWrapper>
  );
}

export default Logo;
