import { useNavigate } from 'react-router';
import { SidebarContext } from 'src/contexts/SidebarContext';

const { useEffect, useCallback, useContext } = require('react');
const { default: NAV_SHORTCUTS } = require('src/config/shortcuts.config');

const useNavShortcuts = () => {
  const shortcutMap = new Map();

  const { closeSidebar } = useContext(SidebarContext);

  NAV_SHORTCUTS.forEach((shortcut) =>
    shortcutMap.set(shortcut.keyCode, shortcut.goto)
  );

  const navigate = useNavigate();

  const createShortcuts = useCallback((e) => {
    if (e.ctrlKey && e.shiftKey && shortcutMap.has(e.keyCode)) {
      e.preventDefault();
      closeSidebar();
      navigate(shortcutMap.get(e.keyCode));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', createShortcuts, true);
  }, []);
};

export default useNavShortcuts;
