import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const SimInventoryModule = new Module({
  id: 'sim-inventory',
  name: 'Sim Inventory',
  label: 'Sim Inventory',
  path: '/sim-inventory',
  Component: ComponentLoader(
    lazy(() => import('src/content/Inventory/Sim/View'))
  ),
  actions: {
    // rollbackBulk: {
    //   id: 'rollback-sim-bulk',
    //   name: 'rollbackSimBulk',
    //   label: 'Rollback SIM'
    // },
    addSimBulk: {
      id: 'add-sim-bulk',
      name: 'addSimBulk',
      label: 'Bulk Upload'
    },
    allocateSimeBulk: {
      id: 'allocate-sim-bulk',
      name: 'allocateSimeBulk',
      label: 'Allocate'
    },
    allocateSimBulk: {
      id: 'allocate-sim-bulk',
      name: 'allocateSimeBulk',
      label: 'Allocate'
    },
    rollbackSimBulk: {
      id: 'rollback-sim-bulk',
      name: 'rollbackSimeBulk',
      label: 'Roll Back'
    },
    viewSimDetails: {
      id: 'view-sim-details',
      name: 'viewSimDetails',
      label: 'View Details'
    },
    simInventoryDiagnosis: {
      id: 'sim-diagnosis-inventory',
      name: 'simDiagnosisInventory',
      label: 'Diagnose'
    }
  }
});

export default new ModuleBundle('Sim Inventory', [SimInventoryModule]);
