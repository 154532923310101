/* eslint-disable no-restricted-syntax */
import Module from 'src/config/models/Module';

const generateRoutes = (modules) =>
  modules.map((module) => ({
    path: module.subPathDef,
    id: module.id,
    element: <module.Component module={module} />
  }));

const validateModules = (modules = []) => {
  const basePaths = new Set();
  const subPathDefs = new Set();
  for (let module of modules) {
    if (!(module instanceof Module))
      throw new Error(`${Module.name} is not a valid`);
    basePaths.add(module.basePath);
    subPathDefs.add(module.subPathDef);
  }

  if (basePaths.size > 1)
    throw new Error(
      `Multiple base paths found for modules ${modules.map(({ name }) => name)}`
    );

  if (modules.length !== subPathDefs.size)
    throw new Error(
      `Common paths found for modules ${modules.map(({ name }) => name)}`
    );
};

const registerModulesWithRouter = ({ modules }) => {
  validateModules(modules);
  const basePath = modules[0].basePath;

  return {
    path: basePath,
    children: generateRoutes(modules)
  };
};

export default registerModulesWithRouter;
