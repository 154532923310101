/* eslint-disable no-restricted-syntax */
const moment = require('moment-timezone');
// const tz = require("")

const dateFormat = 'DD/MM/YYYY';
const timeFormat = 'hh:mm A';
const timeFormat24 = 'HH:mm:ss';

export default class DateTimeService {
  static initDate(dateTime) {
    return moment(dateTime).tz('Asia/Kolkata');
  }

  static getDateTimeString(dateTime) {
    return this.initDate(dateTime).format(`${dateFormat} ${timeFormat}`);
  }
  static getDateTimeStringWithSecs(dateTime) {
    return this.initDate(dateTime).format(`${dateFormat} hh:mm:ss A`);
  }

  static getTimeString(dateTime) {
    return this.initDate(dateTime).format(`${timeFormat}`);
  }

  static get24TimeStringUTC(dateTime) {
    return this.initDate(dateTime).utc().format(`${timeFormat24}`);
  }

  static getJoinedTime(...time) {
    return time.slice(0, 2).join(':');
  }

  static getMillisecondsFromDays(days) {
    return days * 24 * 60 * 60 * 1000;
  }

  static getDateString(dateTime) {
    return this.initDate(dateTime).format(`${dateFormat}`);
  }

  static getDifference(startTime, endTime) {
    return moment.duration(this.initDate(endTime).diff(startTime, null, true));
  }

  static getDifferenceInDays(startTime, endTime) {
    return this.getDifference(startTime, endTime).asDays();
  }

  static getDifferenceInHours(startTime, endTime) {
    return this.getDifference(startTime, endTime).asHours();
  }

  static getDiffInHoursFromDateTimeString(
    startDateTimeString,
    endDateTimeString
  ) {
    const startTime = moment(startDateTimeString);
    const endTime = moment(endDateTimeString);
    const diffInHours = endTime.diff(startTime, 'hours');
    return diffInHours;
  }

  static getDifferenceInMinutes(startTime, endTime) {
    return this.getDifference(startTime, endTime).asMinutes();
  }

  static getDifferenceInSeconds(startTime, endTime) {
    return this.getDifference(startTime, endTime).asSeconds();
  }
  static getDifferenceInMiliseconds(startTime, endTime) {
    return this.getDifference(startTime, endTime).asMilliseconds();
  }

  static getDateTimeFromTimeString(timeString) {
    const timeStamp = new Date().toISOString();
    const datePart = timeStamp.split('T')[0];
    return this.initDate(`${datePart}T${timeString}Z`);
  }

  static getFormatedTimeDiff(startTime, endTime) {
    let diff = this.getDifferenceInSeconds(startTime, endTime);

    const hrs = Math.floor(diff / (60 * 60));

    diff -= hrs * (60 * 60);

    const mins = Math.floor(diff / 60);

    diff -= mins * 60;

    const secs = Math.floor(diff);

    let returnString = `${secs} secs`;

    if (mins) returnString = `${mins} mins ${returnString}`;

    if (hrs) returnString = `${hrs} hrs ${returnString}`;

    return returnString;
  }

  static getDateTimeNDaysAgo(datetime, days) {
    return this.initDate(datetime).subtract(days, 'days');
  }

  static getDateTimeNDaysAfter(datetime, days) {
    return this.initDate(datetime).add(days, 'days');
  }

  static getMidnightTime(datetime) {
    return this.initDate(datetime).endOf('day').toDate();
  }
  static getMorningTime(datetime) {
    return this.initDate(datetime).startOf('day').toDate();
  }

  static getDaysFromArray(daysArr) {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    return daysArr?.map((idx) => days[idx - 1]).join(', ');
  }

  static getIndexfromDays(daysString) {
    const days = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];
    return daysString?.split(',').map((day, i) => {
      return days.indexOf(day.toString().toLowerCase()) + 1;
    });
  }

  static getDaysObject(daysArr) {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday'
    ];
    let daysObj = {};
    for (const [idx] of days.entries())
      daysObj[idx + 1] = !!daysArr.includes(idx + 1);
    return daysObj;
  }

  static toServerTime(momentDate) {
    return momentDate.utc(String).format();
  }

  static toServerDate(csvdate) {
    const [month, date, year] = csvdate.split('/');
    const serverDate = new Date(`${year}-${month}-${date}`);
    return serverDate.toISOString();
  }

  static mergeDateAndTimeString(datestring, timestring) {
    const [dateComponent] = datestring.split('T');
    const [_, timeComponent] = timestring.split('T');
    return `${dateComponent}T${timeComponent}`;
  }

  static getDate(inputDateString) {
    const [day, month, year] = inputDateString.split('/');
    const dateObject = new Date(`${year}-${month}-${day}`);
    const formattedDateString = dateObject.toISOString().split('T')[0];
    return this.initDate(formattedDateString);
  }
}
