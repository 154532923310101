import { useRef, useState } from 'react';

import {
  IconButton,
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  ListItemText,
  Popover,
  Tooltip,
  styled
} from '@mui/material';
import Text from 'src/components/Text';

import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import internationalization from 'src/i18n/i18n';
import { useTranslation } from 'react-i18next';

import usFlag from 'country-flag-icons/3x2/US.svg';
import inFlag from 'country-flag-icons/3x2/IN.svg';

const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);

const ImageWrapper = styled('img')(
  () => `
        width: 30px;
        margin: 3px;
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(7)};
        height: ${theme.spacing(7)};
`
);

function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getLanguage = i18n.language ?? 'en';

  const switchLanguage = ({ lng }) => {
    internationalization.changeLanguage(lng);
  };
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('Language Switcher')}>
        <IconButtonWrapper color="primary" ref={ref} onClick={handleOpen}>
          {getLanguage === 'en' && <ImageWrapper alt="English" src={usFlag} />}
          {getLanguage === 'hi' && <ImageWrapper alt="Hindi" src={inFlag} />}
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          sx={{
            maxWidth: 240
          }}
        >
          <SectionHeading variant="body2" color="text.primary">
            {t('Language Switcher')}
          </SectionHeading>
          <List
            sx={{
              p: 2
            }}
            component="nav"
          >
            <ListItem
              className={getLanguage === 'en' ? 'active' : ''}
              button
              onClick={() => {
                switchLanguage({ lng: 'en' });
                handleClose();
              }}
            >
              <ImageWrapper alt="English" src={usFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="English"
              />
            </ListItem>
            <ListItem
              className={
                getLanguage === 'hi' || getLanguage === 'hi' ? 'active' : ''
              }
              button
              onClick={() => {
                switchLanguage({ lng: 'hi' });
                handleClose();
              }}
            >
              <ImageWrapper alt="Hindi" src={inFlag} />
              <ListItemText
                sx={{
                  pl: 1
                }}
                primary="Hindi"
              />
            </ListItem>
          </List>
          <Divider />
          <Text color="warning">
            <Box
              p={2}
              sx={{
                maxWidth: 340
              }}
            >
              <WarningTwoToneIcon fontSize="small" />
              <Typography variant="body1">
                {t('We only translated a small part of the page')}!
              </Typography>
            </Box>
          </Text>
        </Box>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
