import { DashboardModule } from 'src/content/Dashboard/module';
import { SimInventoryModule } from 'src/content/Inventory/Sim/module';
import { JobViewModule } from 'src/content/Job/module';
import { ActivationViewModule } from 'src/content/requests/Activation/module';
import { RequestModuleView } from 'src/content/requests/Request/module';

const NAV_SHORTCUTS = [
  {
    name: 'Go to Dashboard',
    keyCode: 68,
    key: 'd',
    goto: DashboardModule.path
  },
  {
    name: 'Go to Sim Inventory',
    keyCode: 83,
    key: 's',
    goto: SimInventoryModule.path
  },
  {
    name: 'Go to Requests',
    keyCode: 81,
    key: 'q',
    goto: RequestModuleView.path
  }
];

export default NAV_SHORTCUTS;
