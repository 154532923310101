import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const data = [
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Rahul Sharma',
    driverId: 'EMP001',
    driverContact: '+91 9876543210',
    startDate: '2023-07-10T00:00:00Z',
    endDate: '2023-07-12T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Amit Patel',
    driverId: 'EMP002',
    driverContact: '+91 8765432109',
    startDate: '2023-07-12T00:00:00Z',
    endDate: '2023-07-15T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Sandeep Singh',
    driverId: 'EMP003',
    driverContact: '+91 7654321098',
    startDate: '2023-07-15T00:00:00Z',
    endDate: '2023-07-19T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Rahul Sharma',
    driverId: 'EMP001',
    driverContact: '+91 9876543210',
    startDate: '2023-07-19T00:00:00Z',
    endDate: '2023-07-20T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Amit Patel',
    driverId: 'EMP002',
    driverContact: '+91 8765432109',
    startDate: '2023-07-20T00:00:00Z',
    endDate: '2023-07-22T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Sandeep Singh',
    driverId: 'EMP003',
    driverContact: '+91 7654321098',
    startDate: '2023-07-22T00:00:00Z',
    endDate: '2023-07-24T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Rahul Sharma',
    driverId: 'EMP001',
    driverContact: '+91 9876543210',
    startDate: '2023-07-24T00:00:00Z',
    endDate: '2023-07-26T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Amit Patel',
    driverId: 'EMP002',
    driverContact: '+91 8765432109',
    startDate: '2023-07-26T00:00:00Z',
    endDate: '2023-07-29T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Sandeep Singh',
    driverId: 'EMP003',
    driverContact: '+91 7654321098',
    startDate: '2023-07-29T00:00:00Z',
    endDate: '2023-07-31T00:00:00Z'
  },
  {
    vehicleName: 'Maruti Swift',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    vehicleNo: 'MH01AB1234',
    driverName: 'Rahul Sharma',
    driverId: 'EMP001',
    driverContact: '+91 9876543210',
    startDate: '2023-07-31T00:00:00Z',
    endDate: null
  }
];

mock.onGet('/api/driver-change').reply(() => {
  return [200, data];
});
