import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const AuditTrailViewModule = new Module({
  id: 'audit-trail-view',
  name: 'Audit Trail',
  path: 'audit-trail',
  Component: ComponentLoader(
    lazy(() => import('src/content/Report/AuditTrail/View'))
  )
});

const AuditTrailModules = [AuditTrailViewModule];

export default new ModuleBundle('AuditTrail', AuditTrailModules);
