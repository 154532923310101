import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const WorkCalendarView = new Module({
  id: 'work-calendar',
  name: 'Work Calendar',
  label: 'Work Calendar',
  path: '/work-calendar',
  isBeta: true,
  Component: ComponentLoader(lazy(() => import('src/content/WorkCalendar'))),
  actions: {
    cancel: {
      id: 'cancel-whitelist',
      name: 'cancel-whitelist',
      label: 'Cancel Whitelist'
    }
  }
});

const WorkCalendarModule = [WorkCalendarView];

export default new ModuleBundle('Work Calendar', WorkCalendarModule);
