import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const PlanViewModule = new Module({
  id: 'plan-view',
  name: 'Plan',
  path: 'plans',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Plans/View'))
  ),
  actions: {
    seeliveTracking: {
      id: 'see-live-tracking',
      name: 'change-plan-live-tracking',
      label: 'See Live Tracking'
    },
    seeRoutePlayback: {
      id: 'see-route-playback',
      name: 'see-plan-route-playback',
      label: 'See Route Playback'
    }
  }
});

export const PlanAddModule = new Module({
  id: 'plan-add',
  name: 'planAdd',
  label: 'Plan Add',
  path: 'plans/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Plans/Form'))
  ),
  actions: {
    add: {
      id: 'add-plan',
      name: 'add-plan',
      label: 'Add Plan'
    }
  }
});

export const PlanEditModule = new Module({
  id: 'plan-edit',
  name: 'planEdit',
  path: 'plans/edit',
  pathDef: 'plans/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Plans/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-plan-result-table',
      name: 'edit-plan-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-plan-result-table',
      name: 'delete-plan-result-table',
      label: 'Delete'
    }
  }
});

const PlanModules = [PlanViewModule, PlanEditModule, PlanAddModule];

export default new ModuleBundle('Plan', PlanModules);
