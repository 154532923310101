import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const data = [
  {
    vehicleName: 'Maruti Swift',
    vehicleNo: 'MH01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    driverName: 'Rahul Sharma',
    driverId: 'EMP001',
    businessUnit: 'Delivery',
    fromTime: '2023-07-10T08:00:00Z',
    toTime: '2023-07-20T08:00:00Z'
  },
  {
    vehicleName: 'Hyundai Creta',
    vehicleNo: 'GJ05CD5678',
    vehicleId: '615e8b3c8e39052a9e6c3542',
    driverName: 'Amit Patel',
    driverId: 'EMP002',
    businessUnit: 'Employee Transport',
    fromTime: '2023-06-25T08:00:00Z',
    toTime: '2023-07-10T08:00:00Z'
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'DL07EF9012',
    vehicleId: '615e8b3c8e39052a9e6c3543',
    driverName: 'Sandeep Singh',
    driverId: 'EMP003',
    businessUnit: 'Loading',
    fromTime: '2023-07-01T08:00:00Z',
    toTime: '2023-07-15T08:00:00Z'
  },
  {
    vehicleName: 'Honda City',
    vehicleNo: 'MH04IJ7890',
    vehicleId: '615e8b3c8e39052a9e6c3544',
    driverName: 'Rajesh Gupta',
    driverId: 'EMP004',
    businessUnit: 'Unloading',
    fromTime: '2023-07-05T08:00:00Z',
    toTime: '2023-07-20T08:00:00Z'
  },
  {
    vehicleName: 'Ford EcoSport',
    vehicleNo: 'RJ14MN4567',
    vehicleId: '615e8b3c8e39052a9e6c3545',
    driverName: 'Ankur Verma',
    driverId: 'EMP005',
    businessUnit: 'Employee Transport',
    fromTime: '2023-06-30T08:00:00Z',
    toTime: null
  },
  {
    vehicleName: 'Mahindra XUV500',
    vehicleNo: 'UP32KL2345',
    vehicleId: '615e8b3c8e39052a9e6c3546',
    driverName: 'Arun Kumar',
    driverId: 'EMP006',
    businessUnit: 'Delivery',
    fromTime: '2023-07-10T08:00:00Z',
    toTime: null
  },
  {
    vehicleName: 'Volkswagen Polo',
    vehicleNo: 'HR26OP5678',
    vehicleId: '615e8b3c8e39052a9e6c3547',
    driverName: 'Nikhil Sharma',
    driverId: 'EMP007',
    businessUnit: 'Loading',
    fromTime: '2023-07-15T08:00:00Z',
    toTime: '2023-07-30T08:00:00Z'
  },
  {
    vehicleName: 'Tata Nexon',
    vehicleNo: 'TN01QR7890',
    vehicleId: '615e8b3c8e39052a9e6c3548',
    driverName: 'Vikas Singh',
    driverId: 'EMP008',
    businessUnit: 'Unloading',
    fromTime: '2023-07-20T08:00:00Z',
    toTime: '2023-08-05T08:00:00Z'
  },
  {
    vehicleName: 'Renault Kwid',
    vehicleNo: 'DL09ST1234',
    vehicleId: '615e8b3c8e39052a9e6c3549',
    driverName: 'Gaurav Patel',
    driverId: 'EMP009',
    businessUnit: 'Employee Transport',
    fromTime: '2023-07-05T08:00:00Z',
    toTime: null
  },
  {
    vehicleName: 'Chevrolet Beat',
    vehicleNo: 'MH02QR5678',
    vehicleId: '615e8b3c8e39052a9e6c3550',
    driverName: 'Rakesh Gupta',
    driverId: 'EMP010',
    businessUnit: 'Delivery',
    fromTime: '2023-06-28T08:00:00Z',
    toTime: '2023-07-13T08:00:00Z'
  }
];

mock.onGet('/api/vehicle-allocation').reply(() => {
  return [200, data];
});
