import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const JobViewModule = new Module({
  id: 'Job',
  name: 'Job',
  path: 'job',
  Component: ComponentLoader(lazy(() => import('src/content/Job'))),
  actions: {
    view: {
      id: 'view-tasks',
      name: 'view-tasks',
      label: 'View Tasks'
    },
    change: {
      id: 'change-status',
      name: 'change-status',
      label: 'Change Status'
    }
  }
});

const JobModules = [JobViewModule];

export default new ModuleBundle('Job', JobModules);
