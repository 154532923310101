import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const NumbersViewModule = new Module({
  id: 'numbers',
  name: 'Whitelist Numbers',
  label: 'Whitelist Numbers',
  path: 'numbers',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/Numbers/View'))
  ),
  actions: {
    cancel: {
      id: 'cancel-whitelist',
      name: 'cancel-whitelist',
      label: 'Cancel Whitelist'
    }
  }
});

export const NumbersAddModule = new Module({
  id: 'numbers-add',
  name: 'NumbersAdd',
  path: 'numbers/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/Numbers/Form'))
  ),
  actions: {
    add: {
      id: 'add-Numbers',
      name: 'add-Numbers',
      label: 'Add Numbers'
    }
  }
});
export const NumberEditModule = new Module({
  id: 'Number-edit',
  name: 'NumberEdit',
  path: 'numbers/edit',
  pathDef: 'numbers/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/Numbers/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-Number-result-table',
      name: 'edit-Number-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-Number-result-table',
      name: 'delete-Number-result-table',
      label: 'Delete'
    }
  }
});
const NumbersModules = [NumbersViewModule, NumbersAddModule, NumberEditModule];

export default new ModuleBundle('White Listing Number', NumbersModules);
