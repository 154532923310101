import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const RoleViewModule = new Module({
  id: 'role-operation',
  name: 'Role',
  path: 'role',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Role/View'))
  ),
  actions: {
    seeliveTracking: {
      id: 'see-live-tracking',
      name: 'change-role-live-tracking',
      label: 'See Live Tracking'
    },
    seeRoutePlayback: {
      id: 'see-route-playback',
      name: 'see-role-route-playback',
      label: 'See Route Playback'
    }
  }
});

export const RoleAddModule = new Module({
  id: 'role-add',
  name: 'roleAdd',
  path: 'role/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Role/Form'))
  ),
  actions: {
    add: {
      id: 'add-role',
      name: 'add-role',
      label: 'Add role'
    }
  }
});

export const RoleEditModule = new Module({
  id: 'role-edit',
  name: 'RoleEdit',
  path: 'role/edit',
  pathDef: 'role/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/Role/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-role-result-table',
      name: 'edit-role-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-role-result-table',
      name: 'delete-role-result-table',
      label: 'Delete'
    },
    sendMail: {
      id: 'send-mail-item',
      name: 'send-mail-item',
      label: 'Send Mail'
    }
  }
});

const RoleModules = [RoleViewModule, RoleEditModule, RoleAddModule];

export default new ModuleBundle('Role', RoleModules);
