import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import EnumAutocomplete from 'src/components/FormInputs/BrandAutocomplete/EnumAutoComplete';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded';
import SentimentDissatisfiedRoundedIcon from '@mui/icons-material/SentimentDissatisfiedRounded';
import {
  Box,
  alpha,
  IconButton,
  Tooltip,
  styled,
  Drawer,
  Button,
  TextField,
  InputBase,
  Typography,
  Chip,
  Slide,
  Dialog,
  Card,
  Stack,
  Divider,
  ListItem,
  ListItemAvatar,
  List,
  ListItemText,
  Avatar,
  AvatarGroup,
  lighten
} from '@mui/material';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router';
import useQueryParams from 'src/hooks/useQueryParams';
import { t } from 'i18next';
import { useTheme } from '@emotion/react';
import SimService from 'src/services/Sim.service';
import MNO_LOGO from 'src/enums/MNOLogo.enum';
import DateTimeService from 'src/utils/DateTimeService';
import SearchService from 'src/services/Search.service';
import useShortcut from 'src/hooks/useShortcut';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);
const SearchInputWrapper = styled(InputBase)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(18)};
    padding: ${theme.spacing(2)};
    width: 100%;
`
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderTopLeftRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '40ch'
    }
  }
}));

const searchable = {
  SIMS: 'Sims',
  REQUESTS: 'Requests',
  USERS: 'Users',
  ORG: 'Organizations'
};

function index() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [params] = useQueryParams();
  const [textFieldQuery, setTextFieldQuery] = useState('');
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);

  const [searchables, setSearchables] = useState(new Set());

  const clearData = () => {
    setData([]);
    setSearchables(new Set());
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    clearData();
  };
  const handelSubmit = async (e) => {
    e.preventDefault();

    if (!textFieldQuery) {
      return toast.error(`Enter a valid search term`);
    }

    const { data: res } = await SearchService?.getSearch(textFieldQuery, {
      from: Array.from(searchables).toString()
    });
    setData(res);

    return res;
  };

  // const handlePage=(e)=>{
  //   e.preventDefault();
  //   return navigate(`/sim-details?number=${number}`);
  // }
  const addSearchable = (el) => {
    setSearchables((prev) => {
      const newSearchables = Array.from(prev);
      newSearchables?.push(el);
      return new Set(newSearchables);
    });
  };
  const removeSearchable = (el) => {
    setSearchables((prev) => {
      const newSearchables = new Set(Array.from(prev));
      newSearchables?.delete(el);
      return newSearchables;
    });
  };

  useShortcut(70, () => {
    setOpen(true);
  });

  useEffect(() => {
    setTextFieldQuery(params?.textFieldQuery || '');
  }, [params]);

  return (
    <>
      <Tooltip arrow title={t('Search')}>
        <Button
          onClick={handleClickOpen}
          color="primary"
          variant="outlined"
          startIcon={<SearchTwoToneIcon />}
        >
          <Typography variant="h5" px={4}>
            Search{' '}
            <Chip
              size="small"
              label="CTRL+SHIFT+F"
              sx={{ transform: 'scale(0.8)', transformOrigin: 'right' }}
            />
          </Typography>
        </Button>
      </Tooltip>
      {!!open && (
        <DialogWrapper
          open={open}
          keepMounted
          maxWidth="sm"
          fullWidth
          scroll="paper"
          onClose={handleClose}
        >
          <Box>
            <form onSubmit={handelSubmit}>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1} display="flex" alignItems="center">
                  <SearchTwoToneIcon
                    sx={{
                      ml: 2,
                      color: theme.colors.secondary.main
                    }}
                  />
                  <SearchInputWrapper
                    value={textFieldQuery}
                    onChange={(e) => setTextFieldQuery(e.target.value)}
                    autoFocus
                    placeholder="Search by ICCID,IMSI,MSISDN "
                    fullWidth
                  />
                  <IconButton
                    onClick={() => {
                      setTextFieldQuery('');
                    }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </Box>
                <Card
                  sx={{
                    ml: 'auto',
                    mr: 2,
                    py: 0.5,
                    px: 1,
                    background: theme.colors.alpha.black[10]
                  }}
                >
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight="bold"
                  >
                    esc
                  </Typography>
                </Card>
              </Box>
            </form>
            <Stack direction="row" spacing={2} p={2}>
              {Object.entries(searchable).map(([key, label]) => (
                <Chip
                  color={searchables.has(key) ? 'primary' : 'secondary'}
                  variant="outlined"
                  label={label}
                  onClick={() => addSearchable(key)}
                  onDelete={
                    searchables.has(key) && (() => removeSearchable(key))
                  }
                />
              ))}
            </Stack>
          </Box>
          <Divider />

          {open && (
            <>
              <Box>
                <Typography variant="h4" px={2} pt={2}>
                  SIMS DETAILS
                </Typography>
              </Box>
              <List>
                {!data?.SIMS?.length && (
                  <ListItem sx={{ px: 2, py: 0, gap: 1 }}>
                    <DoNotDisturbRoundedIcon />
                    <Typography variant="h6">No Sims Found</Typography>
                  </ListItem>
                )}
                {data?.SIMS?.map((d) => (
                  <List
                    sx={{ pt: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      navigate(
                        `/sim-details?number=${
                          d?.primary?.ICCID || d?.provisioned?.[0]?.ICCID
                        }`
                      );
                      setOpen(false);
                      clearData();
                    }}
                  >
                    <List sx={{ p: 0 }}>
                      <ListItem alignItems="flex-start">
                        <Stack direction="row" pr={3}>
                          <Tooltip>
                            <AvatarGroup total={2}>
                              <Avatar
                                alt="Airtel"
                                src={
                                  MNO_LOGO[d?.profile?.split('+')[0]] ||
                                  MNO_LOGO.AIRTEL
                                }
                              />
                              {d?.profile && (
                                <Avatar
                                  alt="Vi"
                                  src={MNO_LOGO[d?.profile?.split('+')[1]]}
                                />
                              )}
                            </AvatarGroup>
                          </Tooltip>
                        </Stack>
                        <ListItemText
                          primary={
                            d?.primary?.ICCID ?? d?.provisioned?.[0]?.ICCID
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="h4"
                                sx={{
                                  color: 'text.primary',
                                  display: 'inline'
                                }}
                              >
                                {!d?.profile
                                  ? 'Provisioned'
                                  : d?.organization?.tradeName}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>

                    <Divider />
                  </List>
                ))}
              </List>
              <Box>
                <Typography variant="h4" px={2} pt={2}>
                  JOBS DETAILS
                </Typography>
              </Box>
              <List>
                {!data?.JOBS?.length && (
                  <ListItem sx={{ px: 2, py: 0, gap: 1 }}>
                    <DoNotDisturbRoundedIcon />
                    <Typography variant="h6">No Jobs Found</Typography>
                  </ListItem>
                )}
                {data?.JOBS?.map((j) => (
                  <List
                    sx={{ pt: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      // navigate("/job-details",{state: {jobBatchId: j?.batchJobId}});
                      navigate(`/request?batchJobId=${j?.batchJobId}`);
                      setOpen(false);
                      clearData();
                    }}
                  >
                    <List sx={{ cursor: 'pointer', p: 0 }}>
                      <ListItem alignItems="flex-start">
                        <Stack direction="row" pr={3}>
                          <Tooltip>
                            <AvatarGroup total={2}>
                              <Avatar
                                alt="Airtel"
                                src={MNO_LOGO[j?.profile?.split('+')[0]]}
                              />
                              <Avatar
                                alt="Vi"
                                src={MNO_LOGO[j?.profile?.split('+')[1]]}
                              />
                            </AvatarGroup>
                          </Tooltip>
                        </Stack>
                        <ListItemText
                          primary={`${j?.batchJobId} • ${j?.simsCount}`}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="h4"
                                sx={{
                                  color: 'text.primary',
                                  display: 'inline'
                                }}
                              >
                                {j?.status}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>

                    <Divider />
                  </List>
                ))}
              </List>
              <Box>
                <Typography variant="h4" px={2} pt={2}>
                  ORGANIZATIONS DETAILS
                </Typography>
              </Box>
              <List>
                {!data?.ORGS?.length && (
                  <ListItem sx={{ px: 2, py: 0, gap: 1 }}>
                    <DoNotDisturbRoundedIcon />
                    <Typography variant="h6">No Organizations Found</Typography>
                  </ListItem>
                )}
                {data?.ORGS?.map((o) => (
                  <List
                    sx={{ pt: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/management/organisation?_id=${o?.id}`);
                      setOpen(false);
                      clearData();
                    }}
                  >
                    <List sx={{ p: 0 }}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              background: lighten(
                                theme.colors.warning.lighter,
                                0.4
                              )
                            }}
                          >
                            <ApartmentRoundedIcon color="warning" />{' '}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={o?.tradeName}
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="h4"
                                sx={{
                                  color: 'text.primary',
                                  display: 'inline'
                                }}
                              >
                                {o?.brandName}
                              </Typography>
                              <Typography>{o?.email}</Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                    <Divider />
                  </List>
                ))}
              </List>
              <Box>
                <Typography variant="h4" px={2} pt={2}>
                  USER DETAILS
                </Typography>
              </Box>
              <List>
                {!data?.USERS?.length && (
                  <ListItem sx={{ px: 2, py: 0, gap: 1 }}>
                    <DoNotDisturbRoundedIcon />
                    <Typography variant="h6">No Users Found</Typography>
                  </ListItem>
                )}
                {data?.USERS?.map((u) => (
                  <List
                    sx={{ pt: '1rem', cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/management/user?_id=${u?.id}`);
                      setOpen(false);
                      clearData();
                    }}
                  >
                    <List sx={{ p: 0 }}>
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              background: lighten(
                                theme.colors.warning.lighter,
                                0.4
                              )
                            }}
                          >
                            <PersonRoundedIcon color="warning" />{' '}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                component="span"
                                variant="h4"
                                sx={{
                                  color: 'text.primary',
                                  display: 'inline'
                                }}
                              >
                                {u?.firstName} {u?.lastName}
                              </Typography>
                            </>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography>
                                {u?.phone} • {u?.email}
                              </Typography>
                              <Typography>
                                {u?.organization?.brandName}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </List>
                    <Divider />
                  </List>
                ))}
              </List>
            </>
          )}
        </DialogWrapper>
      )}
    </>
  );
}

export default index;
