import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const data = [
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-10T08:00:00Z',
    logoutTime: '2023-07-10T18:00:00Z',
    operator: 'Amit Sharma',
    operatorContact: '+91 9876543210',
    duration: 36000000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-11T07:30:00Z',
    logoutTime: '2023-07-11T16:30:00Z',
    operator: 'Rahul Verma',
    operatorContact: '+91 8765432109',
    duration: 32400000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-12T08:15:00Z',
    logoutTime: '2023-07-12T18:15:00Z',
    operator: 'Amit Sharma',
    operatorContact: '+91 9876543210',
    duration: 36000000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-13T07:45:00Z',
    logoutTime: '2023-07-13T16:45:00Z',
    operator: 'Rahul Verma',
    operatorContact: '+91 8765432109',
    duration: 32400000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-14T08:30:00Z',
    logoutTime: '2023-07-14T18:30:00Z',
    operator: 'Amit Sharma',
    operatorContact: '+91 9876543210',
    duration: 36000000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-15T07:15:00Z',
    logoutTime: '2023-07-15T16:15:00Z',
    operator: 'Rahul Verma',
    operatorContact: '+91 8765432109',
    duration: 32400000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-16T08:45:00Z',
    logoutTime: '2023-07-16T18:45:00Z',
    operator: 'Amit Sharma',
    operatorContact: '+91 9876543210',
    duration: 36000000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-17T07:30:00Z',
    logoutTime: '2023-07-17T16:30:00Z',
    operator: 'Rahul Verma',
    operatorContact: '+91 8765432109',
    duration: 32400000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-18T08:00:00Z',
    logoutTime: '2023-07-18T18:00:00Z',
    operator: 'Amit Sharma',
    operatorContact: '+91 9876543210',
    duration: 36000000
  },
  {
    vehicleName: 'Toyota Innova',
    vehicleNo: 'KA01AB1234',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    loginTime: '2023-07-19T07:45:00Z',
    logoutTime: '2023-07-19T16:45:00Z',
    operator: 'Rahul Verma',
    operatorContact: '+91 8765432109',
    duration: 32400000
  }
];

mock.onGet('/api/vehicle-login-logoff').reply(() => {
  return [200, data];
});
