import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import AuditTrailModules from 'src/content/Report/AuditTrail/module';
import PublicApiLogModules from 'src/content/Report/PublicApiLog/module';

const ReportRoutes = [
  registerModulesWithRouter(AuditTrailModules),
  registerModulesWithRouter(PublicApiLogModules)
];

export default ReportRoutes;
