import { WALLET_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class WalletService extends ApiService {
  constructor() {
    super(WALLET_URL);
  }
  payUTransaction(payload) {
    return this.doPost(`${this._url}/payU/transaction`, payload);
  }
  getTransactions(walletId, params = {}) {
    return this.doGet(`${this._url}/${walletId}/transactions`, params);
  }
  getBlockedReasons(walletId, params = {}) {
    return this.doGet(`${this._url}/${walletId}/blocked`, params);
  }
  addMoney(organization, payload = {}) {
    return this.doPut(`${this._url}/add/${organization}`, payload);
  }
  deductMoney(organization, payload = {}) {
    return this.doPut(`${this._url}/deduct/${organization}`, payload);
  }
  transferMoney(payload) {
    return this.doPost(`${this._url}/transfer`, payload);
  }

  manualAdjustWallet(action, organization, payload) {
    return this.doPost(`${this._url}/manual/${action}`, {
      organization,
      ...payload
    });
  }
}

export default new WalletService();
