import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const RequestModuleView = new Module({
  id: 'request',
  name: 'Request',
  path: 'request',
  Component: ComponentLoader(
    lazy(() => import('src/content/requests/Request/index'))
  ),
  actions: {
    cancel: {
      id: 'cancel-request',
      name: 'cancel-request',
      label: 'Cancel Request'
    },
    details: {
      id: 'view-detail-request',
      name: 'view-detail-request',
      label: 'View Details'
    },
    modifyCheckpoint: {
      id: 'checkpoint-modify',
      name: 'checkpoint-modify',
      label: 'Checkpoint Modify'
    },
    respond: {
      id: 'respond-request',
      name: 'respond-request',
      label: 'Respond Request'
    },
    closeRequest: {
      id: 'close-request',
      name: 'close-request',
      label: 'Close Request'
    },
    automationInsights: {
      id: 'automation-insights-request',
      name: 'automation-insights-request',
      label: 'Request Automation Insights'
    }
  }
});

export const RequestModuleAdd = new Module({
  id: 'request-add',
  name: 'Add Request',
  path: 'request/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/requests/Request/Form'))
  ),
  actions: {}
});

const RequestModules = [RequestModuleView, RequestModuleAdd];

export default new ModuleBundle('One Requests', RequestModules);
