import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DashboardModule = new Module({
  id: 'dashboard',
  name: 'Dashboard',
  label: 'Dashboard',
  path: '/dashboard',
  Component: ComponentLoader(lazy(() => import('src/content/Dashboard')))
});

export default new ModuleBundle('Admin Dashboard', [DashboardModule]);
