import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';

import { CssBaseline } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { ConfirmProvider } from 'material-ui-confirm';

import './styles/custom.style.css';
import ThemeProvider from './theme/ThemeProvider';
import AppInit from './components/AppInit';
import useNavShortcuts from './hooks/useShortcuts';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  useNavShortcuts();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ConfirmProvider>
          <SnackbarProvider
            maxSnack={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <CssBaseline />
            {auth.isInitialized ? content : <AppInit />}
          </SnackbarProvider>
          <Toaster position="top-center" reverseOrder={false} />
        </ConfirmProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
