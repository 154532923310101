import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import ActivationModules from 'src/content/requests/Activation/module';
import TerminationModules from 'src/content/requests/Termination/module';
import NumbersModule from 'src/content/WhiteListing/Numbers/module';
import IPModule from 'src/content/WhiteListing/IPs/module';

// Management

const requestsRoutes = [
  registerModulesWithRouter(ActivationModules),
  registerModulesWithRouter(TerminationModules),
  registerModulesWithRouter(NumbersModule),
  registerModulesWithRouter(IPModule)
];

export default requestsRoutes;
