const registerModuleWithMenu = (module, { icon } = {}) => ({
  id: module.id,
  icon,
  name: module.label ?? module.name,
  link: module.path.startsWith('/') ? module.path : `/${module.path}`,
  isNew: module.isNew,
  isBeta: module.isBeta
});

export default registerModuleWithMenu;
