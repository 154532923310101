import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const SimBulkActions = new Module({
  id: 'sim-actions',
  name: 'Sim Actions',
  label: 'Sim Actions',
  path: '/sim-actions',
  isNew: true,
  Component: ComponentLoader(lazy(() => import('src/content/SimBulkActions')))
});

export default new ModuleBundle('Sim Actions', [SimBulkActions]);
