import { SIM_URL } from 'src/config/api.config';
import ApiService from './Api.service';

class SimService extends ApiService {
  constructor() {
    super(SIM_URL);
  }
  allocateSims = async (payload) => {
    return this.doPut(`${SIM_URL}/allocate`, payload, null, {
      ...this._headers
    });
  };

  rollbackSims = async (payload) => {
    return this.doPut(`${SIM_URL}/rollback`, payload, null, {
      ...this._headers
    });
  };
  updateSims = async (payload, params) => {
    return this.doPut(SIM_URL, payload, params, {
      ...this._headers
    });
  };

  getDeviceInfo = async (query) => {
    return this.doGet(`${SIM_URL}/device-details`, query, { ...this._headers });
  };

  getSIMDiagnosis = async (query) => {
    return this.doGet(`${SIM_URL}/sim-diagnosis`, query, { ...this._headers });
  };

  // getSIMSuggestion = async (query) => {
  //   console.log(query);
  //   return this.doGet(`${SIM_URL}/suggestions`, {number:query});
  // };

  updateSimImeis = async (payload) => {
    return this.doPut(
      `${SIM_URL}/imeis`,
      payload,
      {},
      {
        ...this._headers
      }
    );
  };

  doBulkActions = async (payload) => {
    return this.doPut(
      `${SIM_URL}/bulk-action`,
      payload,
      {},
      {
        ...this._headers
      }
    );
  };
  getLargeData = async (payload) => {
    return this.doPost(`${SIM_URL}/large-data`, payload, {
     ...this._headers
    });
  };
}

export default new SimService();
