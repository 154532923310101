import DeviceModules from 'src/content/management/Devices/module';
import registerModulesWithRouter from 'src/config/AccessControl/registerModulesWithRouter';
import UserModules from 'src/content/management/Users/module';
import RoleModules from 'src/content/management/Role/module';
import OrganisationModules from 'src/content/management/Organisation/module';
import PlanModules from 'src/content/management/Plans/module';
import WalletOperationModules from 'src/content/management/WalletTransaction/module';

// Management
const managementRoutes = [
  registerModulesWithRouter(DeviceModules),
  registerModulesWithRouter(RoleModules),
  registerModulesWithRouter(OrganisationModules),
  registerModulesWithRouter(UserModules),
  registerModulesWithRouter(PlanModules),
  registerModulesWithRouter(WalletOperationModules)
  // registerModulesWithRouter(WalletModules),
 
];

export default managementRoutes;
