import { useState, forwardRef } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Link,
  TextField,
  Typography,
  Container,
  Alert,
  Slide,
  Dialog,
  Collapse,
  Button,
  Avatar,
  IconButton,
  styled,
  Stack,
  CircularProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';

import useRefMounted from 'src/hooks/useRefMounted';

import { useTranslation } from 'react-i18next';
import Logo from 'src/components/LogoSign';
import AuthService from 'src/services/Auth.service';
import { toast } from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import useAuth from 'src/hooks/useAuth';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.main};
      color: ${theme.palette.success.contrastText};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      box-shadow: ${theme.colors.shadows.success};
      top: -${theme.spacing(6)};
      position: absolute;
      left: 50%;
      margin-left: -${theme.spacing(6)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

function ChangePassword() {
  const { t } = useTranslation();
  const isMountedRef = useRefMounted();
  const navigate = useNavigate();

  const { user } = useAuth();

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [openAlert, setOpenAlert] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Helmet>
        <title>Change Password</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="sm">
          <Logo />
          <Card
            sx={{
              mt: 3,
              p: 4
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 1
                }}
              >
                {t('Change Password')}
              </Typography>
              <Typography
                variant="h4"
                color="text.secondary"
                fontWeight="normal"
                sx={{
                  mb: 3
                }}
              >
                {t(
                  'Enter the cuurent and new password to change your password.'
                )}
              </Typography>
            </Box>

            <Formik
              initialValues={{}}
              validationSchema={Yup.object().shape({
                currentPassword: Yup.string().required(
                  'Current Password is Required'
                ),
                newPassword: Yup.string()
                  .min(8, 'Password Must be atleast 8 character')
                  .required('New Password is Required'),
                confirmPassword: Yup.string()
                  .oneOf(
                    [Yup.ref('newPassword'), null],
                    'Confirm Password doest not  match'
                  )
                  .required('Confirm Password is Required')
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                setSubmitting(true);
                const res = await AuthService.ChangePassword(
                  user?.phone,
                  values?.currentPassword,
                  values?.newPassword
                );
                if (res.success) {
                  toast.success(res.message);
                  navigate('/');
                  if (isMountedRef.current) {
                    setStatus({ success: true });
                  }
                } else {
                  toast.error(res.message);
                }
                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                isSubmitting,
                values
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <TextField
                    error={touched.currentPassword && errors.currentPassword}
                    fullWidth
                    helperText={
                      touched.currentPassword && errors.currentPassword
                    }
                    required
                    label={t('Enter Current Password')}
                    margin="normal"
                    name="currentPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showCurrentPassword ? 'text' : 'password'}
                    value={values.currentPassword}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            setShowCurrentPassword(!showCurrentPassword)
                          }
                        >
                          {showCurrentPassword ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      )
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t('Enter New Password')}
                    margin="normal"
                    name="newPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    error={touched.newPassword && errors.newPassword}
                    helperText={touched.newPassword && errors.newPassword}
                    type={showNewPassword ? 'text' : 'password'}
                    value={values.newPassword}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                        >
                          {showNewPassword ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      )
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t('Confirm New Password')}
                    error={touched.confirmPassword && errors.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    required
                    margin="normal"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffRoundedIcon />
                          ) : (
                            <VisibilityRoundedIcon />
                          )}
                        </IconButton>
                      )
                    }}
                  />

                  <Button
                    sx={{
                      mt: 3
                    }}
                    color="primary"
                    type="submit"
                    fullWidth
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                  >
                    {t('Change Password')}
                  </Button>
                </form>
              )}
            </Formik>
          </Card>
        </Container>
        <DialogWrapper
          open={openDialog}
          maxWidth="sm"
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDialog}
        >
          <Box
            sx={{
              px: 4,
              pb: 4,
              pt: 10
            }}
          >
            <Stack gap={2}>
              <AvatarSuccess>
                <CheckTwoToneIcon />
              </AvatarSuccess>

              <Collapse in={openAlert}>
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="info"
                >
                  {t('The Password Changed successfully')}
                </Alert>
              </Collapse>

              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={handleCloseDialog}
                href="/"
              >
                {t('Continue to login')}
              </Button>

              <Button
                fullWidth
                size="large"
                variant="contained"
                onClick={handleCloseDialog}
                href="/"
              >
                {t('Continue to Dashboard')}
              </Button>
            </Stack>
          </Box>
        </DialogWrapper>
      </MainContent>
    </>
  );
}

export default ChangePassword;
