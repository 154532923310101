import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const SIMDiagnosisViewModule = new Module({
  id: 'sim-diagnosis',
  name: 'SIM Diagnosis',
  label: 'Sim Diagnosis',
  path: '/sim-diagnosis',
  Component: ComponentLoader(
    lazy(() => import('src/content/SIMDiagnosis/View'))
  ),
  actions: {
    refreshDiagnosis: {
      id: 'refresh-sim-diagnosis',
      name: 'refreshDiagnosis',
      label: 'Refresh'
    }
  }
});

export default new ModuleBundle('SIMDiagnosis', [SIMDiagnosisViewModule]);
