import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const ActivationViewModule = new Module({
  id: 'Activation-operation',
  name: 'Activation',
  path: 'activation',
  Component: ComponentLoader(
    lazy(() => import('src/content/requests/Activation/View'))
  ),
  actions: {
    cancel: {
      id: 'cancel-activation',
      name: 'cancel-activation',
      label: 'Cancel Activation'
    }
  }
});

export const ActivationAddModule = new Module({
  id: 'Activation-add',
  name: 'activationAdd',
  path: 'activation/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/requests/Activation/Form'))
  ),
  actions: {
    add: {
      id: 'add-activation',
      name: 'add-activation',
      label: 'Add Activation'
    }
  }
});

const ActivationModules = [ActivationViewModule, ActivationAddModule];

export default new ModuleBundle('Activation', ActivationModules);
