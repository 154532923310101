import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const WalletViewModule = new Module({
  id: 'wallet',
  name: 'Wallet',
  path: 'wallet',
  // pathDef: 'wallet/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/Wallet/View'))
  ),

 
});

// // export const WalletAddModule = new Module({
// //   id: 'wallet-add',
// //   name: 'WalletAdd',
// //   path: 'wallet/add',
// //   Component: ComponentLoader(
// //     lazy(() => import('src/content/management/Wallet/Form'))
// //   ),
// //   actions: {
// //     add: {
// //       id: 'add-wallet',
// //       name: 'add-wallet',
// //       label: 'Add wallet'
// //     }
// //   }
// });

// export const WalletEditModule = new Module({
//   id: 'wallet-edit',
//   name: 'WalletEdit',
//   path: 'wallet/edit',
//   pathDef: 'wallet/edit/:id',
//   Component: ComponentLoader(
//     lazy(() => import('src/content/management/Wallet/Form'))
//   ),
//   actions: {
//     edit: {
//       id: 'edit-wallet-result-table',
//       name: 'edit-wallet-result-table',
//       label: 'Edit'
//     },
//     delete: {
//       id: 'delete-wallet-result-table',
//       name: 'delete-wallet-result-table',
//       label: 'Delete'
//     },
//     sendMail: {
//       id: 'send-mail-item',
//       name: 'send-mail-item',
//       label: 'Send Mail'
//     }
//   }
// });

const WalletModules = [WalletViewModule];

export default new ModuleBundle('Wallet', WalletModules);
