const cleanObject = (payload) => {
  const obj = {};
  Object.keys(payload).forEach((key) => {
    if (payload[key] || payload[key] === false || payload[key] === 0) {
      obj[key] = payload[key];
    }
  });
  return obj;
};

export default cleanObject;
