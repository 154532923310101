import WalletService from 'src/services/Wallet.service';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const initialState = {
  loading: false,
  error: null,
  data: {}
};

export const fetchWalletDetails = createAsyncThunk(
  'fetchWallet',
  async (filter) => {
    const {
      data: [wallet]
    } = await WalletService.get(filter);
    return wallet;
  }
);

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWalletDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchWalletDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action?.payload || {};
    });
    builder.addCase(fetchWalletDetails.rejected, (state) => {
      state.loading = false;
      state.error = 'Failed to fetch wallet details.';
    });
  }
});

export default slice.reducer;
