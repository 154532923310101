import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const TerminationViewModule = new Module({
  id: 'termination-operation',
  name: 'Termination',
  path: 'termination',
  Component: ComponentLoader(
    lazy(() => import('src/content/requests/Termination/View'))
  )
});

export const TerminationAddModule = new Module({
  id: 'termination-add',
  name: 'TerminationAdd',
  path: 'termination/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/requests/Termination/Form'))
  ),
  actions: {
    add: {
      id: 'add-Termination',
      name: 'add-Termination',
      label: 'Add Termination'
    }
  }
});

const TerminationModules = [TerminationViewModule, TerminationAddModule];

export default new ModuleBundle('Termination', TerminationModules);
