import { Box } from '@mui/material';
import useMobile from 'src/hooks/useMobile';

import useAuth from 'src/hooks/useAuth';
import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';
import FullscreenSwitcher from './FullScreenSwitcher';
import ShortcutOptions from './ShortcutOptions';
import WalletBalance from './WalletBalance';

function HeaderButtons() {
  const { user } = useAuth();

  const mobile = useMobile();

  return (
    <Box display="flex">
      {!!user?.organization && <WalletBalance />}
      {/* <HeaderNotifications /> */}
      {!mobile && <ShortcutOptions />}
      {!mobile && <FullscreenSwitcher />}
      {!mobile && <LanguageSwitcher />}
      {/* <LanguageSwitcher /> */}
    </Box>
  );
}

export default HeaderButtons;
