import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const DeviceViewModule = new Module({
  id: 'device-inventory',
  name: 'Device Inventory',
  label: 'Device Inventory',
  path: 'device-inventory',
  Component: ComponentLoader(
    lazy(() => import('src/content/Inventory/Device/View'))
  )
});

export const DeviceAddModule = new Module({
  id: 'device-inventory-add',
  name: 'deviceInventoryAdd',
  path: 'device-inventory/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/Inventory/Device/Form'))
  ),
  actions: {
    add: {
      id: 'add-device-inventory',
      name: 'add-device-inventory',
      label: 'Add Device Inventory'
    }
  }
});

const DeviceInventoryModules = [DeviceViewModule, DeviceAddModule];

export default new ModuleBundle('Device Inventory', DeviceInventoryModules);
