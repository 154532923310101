import { mock } from 'src/utils/axios';
import { v4 as uuid } from 'uuid';

const data = [
  {
    vehicleName: 'Car1',
    vehicleId: '615e8b3c8e39052a9e6c3541',
    geofenceName: 'Office Geofence',
    type: 'Entry',
    timestamp: '2023-07-25T10:15:00Z'
  },
  {
    vehicleName: 'Truck1',
    vehicleId: '615e8b3c8e39052a9e6c3542',
    geofenceName: 'Warehouse Geofence',
    type: 'Entry',
    timestamp: '2023-07-25T11:30:00Z'
  },
  {
    vehicleName: 'SUV1',
    vehicleId: '615e8b3c8e39052a9e6c3543',
    geofenceName: 'Home Geofence',
    type: 'Entry',
    timestamp: '2023-07-25T15:45:00Z'
  },
  {
    vehicleName: 'Car2',
    vehicleId: '615e8b3c8e39052a9e6c3544',
    geofenceName: 'Warehouse Geofence',
    type: 'Exit',
    timestamp: '2023-07-25T16:00:00Z'
  },
  {
    vehicleName: 'Truck2',
    vehicleId: '615e8b3c8e39052a9e6c3545',
    geofenceName: 'Office Geofence',
    type: 'Exit',
    timestamp: '2023-07-25T17:20:00Z'
  },
  {
    vehicleName: 'SUV2',
    vehicleId: '615e8b3c8e39052a9e6c3546',
    geofenceName: 'Home Geofence',
    type: 'Entry',
    timestamp: '2023-07-25T18:30:00Z'
  },
  {
    vehicleName: 'Car3',
    vehicleId: '615e8b3c8e39052a9e6c3547',
    geofenceName: 'Office Geofence',
    type: 'Exit',
    timestamp: '2023-07-25T19:15:00Z'
  },
  {
    vehicleName: 'Truck3',
    vehicleId: '615e8b3c8e39052a9e6c3548',
    geofenceName: 'Warehouse Geofence',
    type: 'Entry',
    timestamp: '2023-07-25T20:00:00Z'
  },
  {
    vehicleName: 'SUV3',
    vehicleId: '615e8b3c8e39052a9e6c3549',
    geofenceName: 'Home Geofence',
    type: 'Exit',
    timestamp: '2023-07-25T21:45:00Z'
  },
  {
    vehicleName: 'Car4',
    vehicleId: '615e8b3c8e39052a9e6c3550',
    geofenceName: 'Office Geofence',
    type: 'Entry',
    timestamp: '2023-07-25T22:30:00Z'
  }
];

mock.onGet('/api/geofence-entry-exit').reply(() => {
  return [200, data];
});
