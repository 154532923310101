import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const IPViewModule = new Module({
  id: 'ip',
  name: 'Whitelist IP',
  label: 'Whitelist IP',
  path: 'ip',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/IPs/View'))
  ),
  actions: {
    cancel: {
      id: 'cancel-whitelist',
      name: 'cancel-whitelist',
      label: 'Cancel Whitelist'
    }
  }
});

export const IPAddModule = new Module({
  id: 'ip-add',
  name: 'IPAdd',
  path: 'ip/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/IPs/Form'))
  ),
  actions: {
    add: {
      id: 'add-IP',
      name: 'add-IP',
      label: 'Add IP'
    }
  }
});
export const IPEditModule = new Module({
  id: 'IP-edit',
  name: 'IPEdit',
  path: 'ip/edit',
  pathDef: 'ip/edit/:id',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/IPs/Form'))
  ),
  actions: {
    edit: {
      id: 'edit-IP-result-table',
      name: 'edit-IP-result-table',
      label: 'Edit'
    },
    delete: {
      id: 'delete-IP-result-table',
      name: 'delete-IP-result-table',
      label: 'Delete'
    }
  }
});

const IPModules = [IPViewModule, IPAddModule, IPEditModule];

export default new ModuleBundle('White Listing IP', IPModules);
