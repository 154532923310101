import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const PublicApiLogModule = new Module({
  id: 'public-api-log',
  name: 'Api Log',
  path: 'api-log',
  Component: ComponentLoader(
    lazy(() => import('src/content/Report/PublicApiLog/View'))
  ),
  actions: {
    viewDetails: {
      id: 'view-details',
      name: 'viewDetails',
      label: 'View Details'
    },
    viewDaily: {
      id: 'view-daily',
      name: 'viewDaily',
      label: 'View Daily'
    },
    viewOverview: {
      id: 'view-overview',
      name: 'viewOverview',
      label: 'View Overview'
    }
  }
});

const PublicApiLogModules = [PublicApiLogModule];

export default new ModuleBundle('PublicApiLog', PublicApiLogModules);
