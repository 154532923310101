import { de } from 'date-fns/locale';
import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const WalletOperationViewModule = new Module({
  id: 'wallet-operation',
  name: 'Wallet Operation',
  path: 'all-wallet',
  Component: ComponentLoader(
    lazy(() => import('src/content/management/WalletTransaction/View'))
  ),

  actions: {
    send: {
      id: 'send-balance',
      name: 'sendbalance',
      label: 'Send Money'
    },
    deduct: {
      id: 'deduct-balance',
      name: 'deductbalance',
      label: 'Debit Money'
    },
    details: {
      id: 'view-detail-request',
      name: 'view-detail-request',
      label: 'View Details'
    },
    showChild: {
      id: 'show-child-wallet',
      name: 'Show-Child-Wallets',
      label: 'Child Wallets'
    },
    adjustWallet: {
      id: 'adjust-money-wallet',
      name: 'adjust-money-Wallet',
      label: 'Adjust Wallet'
    }
  }
});

// export const OrganisationAddModule = new Module({
//   id: 'organisation-add',
//   name: 'organisationAdd',
//   path: 'organisation/add',
//   Component: ComponentLoader(
//     lazy(() => import('src/content/management/Organisation/Form/add'))
//   ),
//   actions: {
//     add: {
//       id: 'add-organisation',
//       name: 'add-organisation',
//       label: 'Add organisation'
//     }
//   }
// });

// export const OrganisationEditModule = new Module({
//   id: 'organisation-edit',
//   name: 'OrganisationEdit',
//   path: 'organisation/edit',
//   pathDef: 'organisation/edit/:id',
//   Component: ComponentLoader(
//     lazy(() => import('src/content/management/Organisation/Form/edit'))
// ),
//   actions: {
//     edit: {
//       id: 'edit-organisation-result-table',
//       name: 'edit-organisation-result-table',
//       label: 'Edit'
//     },
//     delete: {
//       id: 'delete-organisation-result-table',
//       name: 'delete-organisation-result-table',
//       label: 'Delete'
//     },
//     sendMail: {
//       id: 'send-mail-item',
//       name: 'send-mail-item',
//       label: 'Send Mail'
//     }
//   }
// });

const WalletOperationModules = [WalletOperationViewModule];

export default new ModuleBundle('WalletOperation', WalletOperationModules);
