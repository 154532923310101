import { lazy } from 'react';
import ComponentLoader from 'src/components/ComponentLoader';
import Module from 'src/config/models/Module';
import ModuleBundle from 'src/config/models/ModuleBundle';

export const GroupViewModule = new Module({
  id: 'group',
  name: 'IP',
  label: 'IP',
  path: 'group',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/Groups/View'))
  ),
  actions: {
    add: {
      id: 'add-Group',
      name: 'add-Group',
      label: 'Add Group'
    },
    approve: {
      id: 'approve-request',
      name: 'approve-request',
      label: 'Approve Request'
    },
    changeStatus: {
      id: 'change-status',
      name: 'change-status',
      label: 'Change Status'
    }
  }
});

export const GroupAddModule = new Module({
  id: 'Group-add',
  name: 'GroupAdd',
  path: 'group/add',
  Component: ComponentLoader(
    lazy(() => import('src/content/WhiteListing/Groups/Form'))
  ),
  actions: {
    add: {
      id: 'add-Group',
      name: 'add-Group',
      label: 'Add Group'
    }
  }
});

const GroupModules = [GroupViewModule, GroupAddModule];

export default new ModuleBundle('IP Group', GroupModules);
