import {
  Box,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useNavigate } from 'react-router';
import React, { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import MoneyService from 'src/services/Money.service';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWalletDetails } from 'src/slices/walletReducer';

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
          
          height: ${theme.spacing(4)};
          background-color: rgba(87, 202, 34, 0.1); 
          color: ${theme.palette.success.main}; 

         
  `
);

function WalletBalance() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { available } = useSelector((state) => state?.wallet?.data);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      fetchWalletDetails({
        populate: 'organization',
        organization: user?.organization
      })
    );
  }, []);

  return (
    <Tooltip arrow title="Open Your Wallet">
      <Stack direction="row" alignItems="center" gap={1}>
        <AccountBalanceWalletOutlinedIcon color="primary" />
        <IconButtonWrapper color="success" onClick={() => navigate('/wallet')}>
          <Typography> {`₹${MoneyService.fromServer(available)}`}</Typography>
        </IconButtonWrapper>
      </Stack>
    </Tooltip>
  );
}

export default WalletBalance;
